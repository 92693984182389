import React from 'react';
import { Image, Container, Box } from 'theme-ui';
import SectionHeading from '../components/section-heading';

import ContactImage from '../assets/images/contact_img.png';
import WebsiteData from '../../data/WebsiteData';


const Contact = () => {
    return (
        <section 
        id="contact"
        sx={{
            // marginTop: "70px",
        }}
        >
            <Container
                sx={{
                    minHeight: 400,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginTop: "30px",
                    marginBottom: "100px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>
                    <SectionHeading
                        title={WebsiteData['Contact']['title']}
                        // description={WebsiteData['Contact']['text']}
                    />
                    <Image
                        src={ContactImage}
                    />
                </Box>
            </Container>
        </section>
    )
}

export default Contact;